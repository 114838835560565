import { getLabelByValue } from "../shared/routes";
import jsonData from '../shared/data/alldata.json';

export const fetchLUCDataJson = (fromtype: number, totype: number, tidsramme: number, jordtype: string | number) => {
    const fromtypelabel = getLabelByValue(fromtype);
    const totypelabel = getLabelByValue(totype);
    
    console.log("types used in DB lookup: " + fromtypelabel, totypelabel);
    console.log("tidsramme og jordtype: " + tidsramme, jordtype);

    
    const data = jsonData.filter(item => 
        item["Land use change from"] === fromtypelabel &&
        item["Land use change to"] === totypelabel &&
        item["tidsramme"] === tidsramme &&
        item["Land_use_regulation"] === jordtype
    );

    return data;
};
