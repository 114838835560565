import Forside from "../../pages/Forside";
import Klimaberegning from "../../pages/Klimaberegning";
import DataStatistic from "../../pages/DataStatistic";
import React, { ReactElement, ReactNode } from "react";
import DataForklaring from "pages/DataForklaring";

type TRoute = {
  path: string;
  breadcrumb: string | Element;
  element: ReactElement | ReactNode;
};

export const dataStatisticsRoutes = [
  { value: 1, label: "By/infra" },
  { value: 2, label: "Agerjord" },
  { value: 3, label: "Afgræsningsareal" },
  { value: 4, label: "Skov, produktion" },
  { value: 5, label: "Skov, urørt" },
  { value: 6, label: "Lavbundsjord" },
  { value: 7, label: "Natur, lysåben" },
];

export const forklaringRoutes = [
  { value: 1, label: "Faglige forklaringer" },
  { value: 2, label: "Et eksempel" },
  { value: 3, label: "Antagelser og usikkerheder" }, 
];

export const getLabelByValue = (value: number) => {
  const route = dataStatisticsRoutes.find(route => route.value === value);  
  return route ? route.label : null;
};

// @ts-ignore
const DynamicUserBreadcrumb = ({ match }) => (
  <span>
    {dataStatisticsRoutes.find((i) => i.value === match.params.id)?.label}
  </span>
);

// @ts-ignore
const DynamicForklaringBreadcrumb = ({ match }) => (
  <span>
    {forklaringRoutes.find((i) => i.value === match.params.id)?.label}
  </span>
);

export const routes: TRoute[] = [
  { path: "/", breadcrumb: "Land LCA", element: <Forside /> },
  {
    path: "/klimaberegning",
    breadcrumb: "Klimaberegning",
    element: <Klimaberegning />,
  },
  {
    path: "/databeskrivelse/:id",
    // @ts-ignore
    breadcrumb: DynamicUserBreadcrumb,
    element: <DataStatistic />,
  },
   {
    path: "/forklaring/:id",
    // @ts-ignore
    breadcrumb: DynamicForklaringBreadcrumb,
    element: <DataForklaring />,
  },
];
