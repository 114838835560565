import { Box, Grid, Popover, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { colors } from "../../../shared/const";
import React from "react";

type TProps = {
  title: string;
  content: string;
  id: string;
  onOpen: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
};

const TableHeadPopover = ({
  title,
  content,
  id,
  onOpen,
  onClose,
  open,
  anchorEl,
}: TProps) => {
  return (
    <Box>
      <Grid container direction="row" justifyContent="center">
        <Typography>{title}</Typography>
        <Box
          sx={{ ml: "4px" }}
          onMouseEnter={(e) => onOpen(e, id)}
          onMouseLeave={onClose}
        >
          <InfoOutlined sx={{ fontSize: 20, color: colors.textGrey }} />
        </Box>
      </Grid>
      <Popover
        id={id}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 2, maxWidth: "220px" }}>
          <Typography
            variant="h6"
            gutterBottom={false}
            fontSize={18}
            sx={{ color: colors.greenDark, fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <Typography fontSize={14}>{content}</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default TableHeadPopover;
