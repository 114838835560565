export const colors = {
  primary: "#0a2d3b",
  primaryLight: "#0062C7",
  white: "#FFFFFF",
  dark: "#292B39",
  textGrey: "#aeaeae",
  green: "#a9d18e",
  greenDark: "#548235",
  info: "#548235",
  greyDark: "#a6a6a6",
  grey: "#d8d9d9",
  lightGrey: "#e7e6e6",
  lightGrey400: "#ccd5d7",
  lightGrey300: "rgba(225, 229, 237, 0.25)",
  lightGrey200: "rgba(217, 217, 217, 0.2)",
  lightGrey90: "rgba(221, 226, 234, 0.9)",
  lightGrey50: "rgba(221, 226, 234, 0.5)",
  bgWhite: "rgba(255, 255, 255, 0.9)",
  textLight: "rgba(105, 113, 125, 1)",
  dividerBorder: "rgba(138, 148, 155, 0.15)",
  success: "#01a7d1",
  error: "#C10F5A",
  errorLight: "rgba(193, 15, 90, 0.1)",
  errorDark: "#ED1771",
  statusGreen: "#0cbd66",
  statusYellow: "#F6BF31",
  statusRed: "#B40322",
  statusGrey: "#AFB7C3",
  bgThead: "#1C2E3E",
  bgGradientPrimary: "linear-gradient(63.99deg, #004A96 0%, #006EDE 100%)",
  bgGradientDark: "linear-gradient(270deg, #192D40 0%, #293339 100%)",
  goldGradient:
    "linear-gradient(90deg, rgba(202, 176, 96, 0.15), rgba(202, 176, 96, 0) 100.17%);",
  silverGradient:
    "linear-gradient(90deg, rgba(197, 197, 197, 0.15), rgba(197, 197, 197, 0) 100.17%)",
  bronzeGradient:
    "linear-gradient(90deg,rgba(202, 141, 96, 0.15), rgba(202, 141, 96, 0) 100.17%)",
};
