import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs as BreadcrumbsMui, Stack, Link } from "@mui/material";
import { routes } from "../../../shared/routes";
import { colors } from "../../../shared/const";

function Breadcrumbs() {
  // @ts-ignore
  const breadcrumbs = useBreadcrumbs(routes);
  let navigate = useNavigate();

  const handleClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    pathname: string
  ) => {
    event.preventDefault();
    navigate(pathname);
  };
  return (
    <Stack spacing={2}>
      <BreadcrumbsMui separator="›" aria-label="breadcrumb">
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          return (
            <Link
              underline="hover"
              key={index}
              color="inherit"
              onClick={(e) => handleClick(e, match.pathname)}
              sx={{ cursor: "pointer", color: colors.textGrey }}
            >
              {breadcrumb}
            </Link>
          );
        })}
      </BreadcrumbsMui>
    </Stack>
  );
}

export default Breadcrumbs;
