import { Typography, Link } from "@mui/material";


export const content3 = 
    <>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
            LAND-LCA er et simpelt værktøj, der bygger på komplekse sammenhænge. Værktøjet er ikke en præcis beregning, men et indledende estimat, der kan bruges til at afklare omfanget af klimapåvirkninger af arealanvendelse. Der skal flere beregninger og detaljer om jordbund, mv. til for at give et mere præcist billede af konsekvenserne.
        </Typography>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
            Forsimplingerne vedrører primært opgørelser af CO<sub>2</sub> fra de direkte ændringer i arealanvendelsen, hvor værktøjet bruger standardantagelser omkring:
            <ul>
                <li>kulstof, som lagres ved forskellige arealtyper</li>
                <li>forrådnelsestider (decay) og genvækst af fx nyplantet skov (regrowth)</li>
                <li>tidshorisont for arealanvendelsen (permanent eller 30 år)</li>
            </ul>
            Hvis der findes bedre data for ovenstående, kan der laves mere nøjagtige bereginger. Dog vil resultater stadig være forbundet med usikkerheder, primært relateret til iLUC, som vedrører skovrydning og intensivering af landbrugs udenfor Danmarks grænser.
        </Typography>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
            Der er ligeledes usikkerheder forbundet til mådes, hvorpå tidsdimensionen af CO<sub>2</sub> emissioner håndteres. Vi har anvendt en konsistent anvendelse af IPCC’s global warming potential (GWP). Men en kendt og berettiget kritik af GWP er, at der er en relativt dårlig korrelation mellem temperaturændringer og ændringer i beregnet GWP. Vores anbefaling er, at anvende global temperature potential (GTP) i stedet, da vi hermed direkte beregner ændringer i temperatur, som en funktion af udledning af drivhusgasser og et specificeret tidspunkt, hvor vi ønsker at måle temperaturændringen. Vi har, til trods for usikkerhederne forbundet med GWP, valgt at anvende denne indikator for klimapåvirkninger. Dette skyldes, at dette er den normalt accepterede måde at opgøre drivhusgasser – både ift. landes og kommuners klimamål, og i livscyklusvurdering generelt. 
        </Typography>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
I det enkelte tilfælde med en arealanvendelse på fx 10 hektar fra landbrug til by vil det være naturligt at sætte spørgsmålstegn ved, om en lille ændring mellem to ”ikke-natur” typer i Danmark virkelig kan have en effekt på skovrydning i andre dele af verden. Den beregnede ændring er baseret på videnskabelig principper om årsags-virkningssammenhænge (mellem udbud og efterspørgsel i markeder) og masse-balancer, men som i tilfældet med andre LCA-beregninger kan de faktiske specifikke effekter på skovrydning ikke verificeres. Dette skyldes, at der er tale om kaskader af effekter medieret af globale markeder for afgrøder, gødning, tilgængeligt skovareal til rydning m.v. De forårsagede effekter fra en specifik ændring i arealanvendelsen i Danmark vil til hver en tid sameksistere med effekterne fra alle andre aktiviteter på jorden. Det er umuligt at opstille et eksperiment, hvor effekten af en specifik handling kan isoleres, da systemet er globalt. 
        </Typography>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
      Som nævnt ovenfor kan de beregnede effekter ikke måles isoleret/verificeres, men når vi som samfund bruger arealer til fx byudvikling, infrastrukturer, energiproduktion, mv. på bekostning af landbrugsareal, så vil det alt andet lige betyde, at den nedlagte landbrugsproduktion skal kompenseres andetsteds – hvilket indebærer indirekte effekter. Og de effekter vil vi overse, hvis vi ikke tager højde for dem i de enkelte arealanvendelser.      
        </Typography>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
            Det vil også være nærliggende at sætte spørgsmålstegn ved, om samfundets udvikling med fokus på at spise mindre kød og mere grønt ikke også vil betyde, at vi har brug for færre landbrugsarealer. Det er en god observation, og hvis (og forhåbentligt når) en sådan ændring træder i kraft, vil iLUC modellen opdateres, så der tages højde for ændrede dynamikker i det globale marked for produktivt land. Selv i en situation, hvor menneskeheden bruger væsentlig mindre land (til fx kød) og det dyrkede areal vil være faldende, vil ændringer i arealanvendelsen stadig have en effekt.
        </Typography>



    </>