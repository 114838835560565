import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import Breadcrumbs from "../../components/ui/Breadcrumbs";
import { dataStatisticsRoutes } from "../../shared/routes";

import React from "react";

type RouteType = {
  value: number; 
  label: string;
};
type Params = {
  id: string;
};
const contentMapping: Record<string, string | JSX.Element> = {
  "1": "Arealanvendelse til byområde eller infrastruktur er en permanent forsegling af et areal. Det er eksempelvis motorveje og bygninger.",
  "2": "Agerjord er landbrugsarealer, som årlig eller med få års mellemrum behandles med jordbrugsredskaber",
  "3": "Afgræsningsarealer er arealer, som afgræsses, men som ikke pløjes/omlægges. ",
  "4": "Produktionsskov er skovarealer, hvor skoven dyrkes med henblik på salg af træerne. ",
  "5": "Urørt skov er skovarealer, hvor skoven står urørt, dvs. den kommercielle skovdrift er stoppet. ",
  "6": "Lavbundsjord er arealer, der indeholder meget kulstof. Lavbundsjorde er eller var tidligere vådområder, som enge og moser.",
  "7": "Lysåben natur er enge, strandenge, moser, overdrev og heder. Lysåben natur har samme kulstofindhold som kategorien ’afgræsningsareal’, men der er mere plantevækst over jorden, hvis arealet ikke afgræsses. ",
  
};

const DataStatistic = () => {
  
const getLabelFromValue = (value: string, routes: RouteType[]): string | null => {
  const numValue = parseInt(value, 10);
  const route = routes.find(route => route.value === numValue);
  return route ? route.label : null;
};

  const { id } = useParams<Params>();
  const label = id ? getLabelFromValue(id, dataStatisticsRoutes) : null;
  const content = id && contentMapping[id];


  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      sx={{
        p: { xs: 6, md: 10, lg: 20 },  // Adjust the values (6, 10, 20) as per your design requirements
        height: "100%"
      }}
    >
      <Box>
        {" "}
        <Breadcrumbs />
      </Box>
      <Grid item>
        <h1>{label ? `Beskrivelse af - ${label}` : "Type ikke fundet"}</h1>
        {content ? content : `Databeskrivelse af ${label} er ikke endnu klar. Den kommer snart.`}

      </Grid>
    </Grid>
  );
};

export default DataStatistic;
