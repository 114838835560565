import { Routes, Route, Link } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Grid } from "@mui/material";
import "./App.css";
import { theme } from "./shared/theme";
import Header from "./components/ui/Header";
import Klimaberegning from "./pages/Klimaberegning";
import Forside from "./pages/Forside";
import DataStatistic from "./pages/DataStatistic";
import { routes } from "./shared/routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Grid direction={"column"} sx={{ height: "100vh", display: "flex" }}>
        <Header />
        <Routes>
          {routes.map((route) => (
            <Route path={route.path} element={route.element} />
          ))}
        </Routes>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
