import { Typography, Link } from "@mui/material";


export const content1 = 
<>
    <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
        LAND-LCA er en nem måde at lave et indledende estimat på CO₂-udledningen forårsaget af direkte og indirekte ændringer i arealanvendelsen. Værktøjet bygger på mere end 15 års forskning i konsekvenser af arealanvendelser, og værktøjet er en simpel udgave af komplekse sammenhænge. Den anvendte model bag beregningerne er beskrevet i Schmidt et al. (2015).
    </Typography>
    <Typography variant="h3" sx={{mb: 1}}>Hvorfor fokus på arealanvendelser?</Typography>
    <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
        EU understreger, at jordarealer er en vigtig ressource. Miljøagenturet fremhæver, at ”det har konsekvenser at reducere arealet til landbrug og natur til byudvikling og anden kunstig jordudvikling" (Det Europæiske Miljøagentur, 2019). Europa-Kommissionens køreplan for et ressourceeffektivt Europa (2011) og den "tematiske strategi for jordbundsbeskyttelse" (2006) understreger nødvendigheden af at adressere brugen af jordarealer, og for miljøvurdering indebærer dette overvejelser og begrænsninger af direkte og indirekte arealforbrug. Af samme grund kom jordarealer som miljøfaktor ind i EU-direktivet for miljøvurdering af projekter tilbage i 2014.
    </Typography>
    <Typography variant="h3" sx={{mb: 1}}>Hvad er direkte ændringer i arealanvendelse? </Typography>
    <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
        Direkte ændringer i arealanvendelse (dLUC) beskriver de direkte ændringer, der opstår ved at ændre en arealanvendelse til en anden. De direkte ændringer medfører CO<sub>2</sub>-udledning. Hvis en skov f.eks. omdannes til et byområde, så vil størstedelen kulstoffet i skoven omdannes til CO<sub>2</sub> emissioner.
    </Typography>
    <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
        Det er vigtigt at understrege, at de direkte ændringer i arealanvendelse bestemmes som forskellen mellem den nye/påtænkte arealanvendelse og ”nul-alternativet”, dvs. den arealanvendelse, som vil være på arealet fremover, hvis ikke beslutningen vedr. den nye/påtænkte arealanvendelse bliver realiseret.
    </Typography>
    <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
        I nogle tilfælde vil nul-alternativet være ens med den forrige (historiske) arealanvendelse, mens andre tilfælde vil vise noget andet. Sidstnævnte er især tilfældet, hvis der er flere konkurrerende ændringsforslag til en eksisterende arealanvendelse.
    </Typography>
    <Typography variant="h3" sx={{ mb: 1 }}>Hvad er indirekte ændringer i arealanvendelsen?</Typography>
    <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
        Indirekte ændringer i arealanvendelsen (iLUC) beskriver de ændringer, der opstår indirekte ved at ændre en arealtype til en anden. Hvis landbrugsjord f.eks. omdannes til byområde, skal de afgrøder, der før blev dyrket på arealet, produceres et andet sted i verden. Det kan enten betyde, at der skabes ny landbrugsjord (f.eks. i en regnskov i Brasilien), eller at eksisterende landbrug andre steder intensiveres gennem f.eks. øget brug af gødning og pesticider. Dette skaber emissioner, der skal tages højde for.
    </Typography>
    <Typography variant="h3" sx={{ mb: 1 }}>Hvordan estimerer LAND-LCA CO<sub>2</sub>-udledningerne? </Typography>
    <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
        LAND-LCA estimerer CO<sub>2</sub>-udledningerne ud fra data på kulstofindhold i arealtyper (t C/ha i landbrug, skov, græs etc.), produktionskapaciteten af arealer (indikator for potentielle afgrødeudbytter), og data på, hvorledes landbrugsproduktionen øges vha. hhv. udvidelse af landbrugsjord (som medfører rydning af skov) og hhv. intensivering af eksisterende landbrugsjord (fx vha. øget brug af gødning). Sidstnævnte data fremkommer ved at se på tidsserier af landbrugsproduktion og udbytter, samt at alle afgrøder aggregeres til én afgrøde målt i tons tørstof. Desuden antager modellen, at der er et marked for land, og at dette marked er globalt. Dvs. hvis vi i Danmark udvider byareal ved at inddrage landbrugsareal, så reducerer vi udbuddet af landbrugsjord i Danmark. Denne reduktion i udbud vil så blive kompenseret på det globale marked for land, hvilket kan være: rydning af skov til fordel for landbrugsareal i Sydamerika, Centralafrika og Sydøstasien, og intensivering af fx ris i Indien og majs og hvede i Kina.
    </Typography>
        <Typography variant="h3" sx={{ mb: 1 }}>Hvordan tager modellen højde for tidsdimensionen </Typography>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
            Når en aktivitet ændrer efterspørgslen på land på fx 1 hektar i 1 år, så vil dette ifølge forskningen på området medføre, at omkring 43% (0.43 ha år) imødekommes ved skovrydning og 57% (0.8 ha år) ved intensivering. Emissionerne fra de 0.43 ha år fra skovrydning regnes som 44/12 x C x tidsfaktor. De 44/12 x C er den mængde CO<sub>2</sub>, der dannes, når kulstofindholdet i skoven rådner/brænder. Da den beregnede mængde CO<sub>2</sub> dækker over omdannelse af 0.43 ha land, som i princippet kan vedblive landbrugsjord til evig tid, mens vi kun er interesseret i påvirkningen fra 0.43 hektar i 1 år, så der tages højde for dette. Dette gøres ved at medregne hele CO<sub>2</sub> emissionen i år 0, og så undgå en tilsvarende emission i år 1. Dette skyldes, at efter 1 år gives landet til en anden anvendelse (vi ser i dette eksempel kun på arealanvendelse af 0.43 ha i 1 år). Denne anden anvendelse kan så foregå uden land use change, og dette tilskrives til vores arealanvendelse. Ovenstående kan kort sammenfattes til at efterspørgsel på 0.43 ha land i 1 år medfører, at 0.43 ha afskovning fremrykkes i tid med 1 år. Tidsfaktoren betyder, at når 1 tons CO<sub>2</sub> udledes i år 0 i stedet for år 1, så betyder det 0.0072 kg CO<sub>2</sub>-eq. Tidsfaktoren regnes vha. IPCC’s global warming potential, hvilket er forklaret nærmere i Schmidt et al. (2015).
        </Typography>
        <Typography variant="h3" sx={{ mb: 1 }}>Tidshorisonter og produktivitet for arealanvendelse i modellen </Typography>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
            Modellen giver mulighed for at vælge mellem permanente ændringer til byformål og infrastruktur og midlertidige ændringer på 30 år, hvilket er relevant ved f.eks. solceller. Derudover giver modellen mulighed for at vælge mellem en høj og en lav produktivitet af jorden. En høj produktivitet er simplificeret til at svare til landbrugsjord, der er udpeget som landbrugsjord i kommuneplanen, hvorimod lav produktivitet svarer til at arealer er udpeget som indsatsområder, som sætter begrænsninger på anvendelsen til landbrugsformål.
        </Typography>
                        <Typography variant="h3" sx={{ mb: 1 }}>Mere information </Typography>
      <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
        Hvis du vil læse mere om forskningen bag, anbefaler vi disse publikationer:
        <ul>
            <li>
            Schmidt J, Weidema B P, Brandão M (2015). A framework for modelling indirect land use changes in life cycle assessment. Journal of Cleaner Production 99:230-238.
            </li>
            <li>
            Schmidt J, De Rosa M (2018). Enhancing Land Use Change modelling with IO data. Presentation at the SETAC Europe 28th Annual Meeting, Rome, 13-17 May 2018. 
            <Link href="https://lca-net.com/p/3036" target="_blank" rel="noopener noreferrer">
                https://lca-net.com/p/3036
            </Link>
            </li>
            <li>
            Webinar: 
            <Link href="https://youtu.be/duJ4e1pYQFw" target="_blank" rel="noopener noreferrer">
                https://youtu.be/duJ4e1pYQFw
            </Link>
            </li>
            <li>
            Slides: 
            <Link href="https://lca-net.com/wp-content/uploads/iLUC-webinar-15th-Nov_20171115_2.pdf" target="_blank" rel="noopener noreferrer">
                https://lca-net.com/wp-content/uploads/iLUC-webinar-15th-Nov_20171115_2.pdf
            </Link>
            </li>
        </ul>
        </Typography>

</>

