import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { colors } from "../const";
import * as React from "react";

declare module "@mui/material/styles" {
  interface Palette {
    thead: Palette["primary"];
    lightGrey: Palette["grey"];
    additionalBackground: Palette["background"];
    additionalColors: Palette["primary"];
    status: {
      green: React.CSSProperties["color"];
      yellow: React.CSSProperties["color"];
      red: React.CSSProperties["color"];
      grey: React.CSSProperties["color"];
    };
    gradient: {
      gold: React.CSSProperties["color"];
      silver: React.CSSProperties["color"];
      bronze: React.CSSProperties["color"];
    };
  }
  interface PaletteOptions {
    thead: PaletteOptions["primary"];
    lightGrey: Palette["grey"];
    additionalBackground: Palette["background"];
    additionalColors: PaletteOptions["primary"];
    status: {
      green: React.CSSProperties["color"];
      yellow: React.CSSProperties["color"];
      red: React.CSSProperties["color"];
      grey: React.CSSProperties["color"];
    };
    gradient: {
      gold: React.CSSProperties["color"];
      silver: React.CSSProperties["color"];
      bronze: React.CSSProperties["color"];
    };
  }
}

export const theme = createTheme({
  spacing: 8,
  palette: {
    mode: "light",
    primary: {
      main: colors.primary,
      dark: colors.dark,
      light: colors.primaryLight,
    },
    secondary: {
      main: "#0fbc67",
    },
    additionalColors: {
      main: "#3298FF",
      dark: colors.dark,
    },
    info: {
      main: "#014B97",
      dark: "#024C98",
    },
    error: {
      main: colors.error,
      light: colors.errorLight,
      dark: colors.errorDark,
    },
    success: {
      main: colors.success,
    },
    text: {
      secondary: colors.grey,
    },
    thead: {
      main: colors.bgThead,
      dark: "rgb(32,48,61)",
    },
    grey: {
      500: colors.grey,
      300: "rgb(245,245,245)",
      200: "rgb(235,235,235)",
      100: "rgba(0, 0, 0, 0.15)",
      50: colors.greyDark,
    },
    lightGrey: {
      ...grey,
      900: colors.lightGrey,
      400: colors.lightGrey400,
      300: colors.lightGrey300,
      200: colors.lightGrey200,
      100: colors.lightGrey90,
      50: colors.lightGrey50,
    },
    background: {
      default: colors.bgWhite,
      //paper: 'linear-gradient(270deg, #192D40 0%, #293339 100%)',
    },
    additionalBackground: {
      default: colors.bgGradientPrimary,
      paper: "rgba(0, 126, 255, 0.14)",
    },
    status: {
      green: colors.statusGreen,
      yellow: colors.statusYellow,
      red: colors.statusRed,
      grey: colors.statusGrey,
    },
    gradient: {
      gold: colors.goldGradient,
      silver: colors.silverGradient,
      bronze: colors.bronzeGradient,
    },
    // grey: {
    //   50: "#bcbcbe",
    //   100: "#a5a6a8",
    //   200: "#8f9092",
    //   300: "#787a7c",
    //   400: "#626466",
    //   500: "#4b4d51",
    //   600: "#35373b",
    //   700: "#1e2125",
    //   800: colors.darkGray,
    //   900: "#1b1e21",
    // },
    // divider: colors.dividerBorder,
  },
  typography: {
    fontFamily: " Arial, sans-serif",
    body1: {
      color: colors.textLight,
      // lineHeight: "19.36px",
    },
    h1: {
      fontSize: "32px",
      fontWeight: 700,
    },
    h2: {
      color: colors.dark,
      fontSize: "22px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "18px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19.36px",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: colors.primary,
          boxShadow: "none",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "60px",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: colors.lightGrey,
          padding: 24,
          // // Header height
          // height: "calc(100dvh - 60px)",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          "&": {
            WebkitOverflowScrolling: "touch",
          },
          "&::-webkit-scrollbar": {
            height: 3,
            width: 3,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: colors.greyDark,
            borderRadius: 300,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#69717D",
            borderRadius: 300,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "separate",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: 6,
          paddingRight: 6,
          whiteSpace: "normal",
          fontSize: "14px",
          textSizeAdjust: "none",
          "&:first-of-type": {
            paddingLeft: 24,
          },
          "&:last-of-type": {
            paddingRight: 24,
          },
          borderBottom: "none",
          "& input::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "& input::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        },
        body: {
          color: "inherit",
          borderBottom: "1px solid rgba(255,255,255, 0.1)",
        },
        sizeSmall: {
          height: 28,
          padding: 3,
          paddingLeft: 5,
          paddingRight: 5,
          "&:first-child, &:nth-child(2)": {
            paddingLeft: 12,
          },
        },
        footer: {
          lineHeight: 1.43,
          fontSize: "13px",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "& svg": {
            color: `${colors.white} !important`,
            opacity: 1,
          },
        },
      },
    },
    // MuiFormControl: {
    //   styleOverrides: {
    //     marginNormal: {
    //       marginTop: 12,
    //     },
    //     root: {
    //       "& .MuiOutlinedInput-notchedOutline": {
    //         backgroundColor: colors.white,
    //         borderColor: colors.greyDark,
    //       },
    //       "&.MuiTextField-root .MuiOutlinedInput-notchedOutline": {
    //         backgroundColor: colors.greyDark,
    //         borderColor: colors.white,
    //       },
    //     },
    //   },
    // },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: colors.greyDark,
          zIndex: 1,
        },
        select: {
          zIndex: 1,
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       notchedOutline: {
    //         borderColor: colors.white,
    //         backgroundColor: colors.greyDark,
    //       },
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     notchedOutline: {
    //       borderColor: colors.white,
    //       backgroundColor: colors.greyDark,
    //     },
    //     root: {
    //       "&$focused $notchedOutline": {
    //         borderColor: colors.white,
    //         borderWidth: 1,
    //       },
    //       "&:hover $notchedOutline": {
    //         borderColor: colors.lightGrey,
    //       },
    //     },
    //   },
    // },
    // MuiSelect: {
    //   styleOverrides: {
    //     select: {
    //       "&.MuiOutlinedInput $notchedOutline": {
    //         backgroundColor: colors.white,
    //       },
    //     },
    //   },
    // },
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       color: colors.lightGrey,
    //       "&:hover, &:focus": {
    //         color: colors.white,
    //       },
    //       "&$selected": {
    //         color: colors.white,
    //         backgroundColor: "rgba(138, 148, 155, 0.35)",
    //       },
    //       transition: defaultTheme.transitions.create([
    //         "color",
    //         "background-color",
    //       ]),
    //     },
    //     dense: {
    //       fontSize: 14,
    //     },
    //   },
    // },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&$focused": {
            color: colors.white,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          scrollbarColor: `${colors.grey} ${colors.greyDark}`,
          "&::-webkit-scrollbar": {
            height: 6,
            width: 3,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: colors.greyDark,
            borderRadius: 300,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#69717D",
            borderRadius: 300,
          },
        },
      },
    },
  },
});

(theme as any).colors = colors;

export default theme;
