import React from 'react';
import {Typography, Box, Link, Grid} from '@mui/material';

const Forside = () => {
  return (
    <Grid
      container
      // direction="column"
      // justifyContent="space-around"
      // alignItems="stretch"
       sx={{
        p: { xs: 6, md: 10, lg: 20 },  // Adjust the values (6, 10, 20) as per your design requirements
        height: "100%"
      }}>
      <Grid item>
        <Typography variant="h1" sx={{mb: 4}}>LAND-LCA – klimakonsekvenser af ændringer i arealanvendelser</Typography>

        <Typography variant="h3" sx={{mb: 1}}>Introduktion til LAND-LCA</Typography>
        <Typography variant="body1" component={"p"} sx={{mb: 2}}>
          Arealer er i sig selv en knap ressource: Overalt på jordkloden har hver hektar en funktion, enten som landbrugsproduktion, naturhabitat, byområde eller andet. Hvis vi planlægger for en ny aktivitet, der kræver et areal, sker det derfor på bekostning af noget andet. Alle ændringer i arealanvendelser indebærer derfor påvirkninger. 
        </Typography>
        <Typography variant="body1" component={"p"} sx={{mb: 2}}>
          Mange af påvirkningerne undersøges i dag i miljøvurderinger af planer og projekter. Formålet med Miljøvurderingsloven er, at der tages hensyn til en række miljøfaktorer, og her indgår ”jordarealer” og ”klimatiske faktorer”. Betydningen af arealændringer i forhold til jordarealer overses ofte i miljøvurderinger, og det gør tilhørende klimamæssige påvirkning også.
        </Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          DREAMS-projektet har derfor udviklet værktøjet ”LAND-LCA”. Formålet med LAND-LCA er at skabe en større forståelse for klimakonsekvenserne ved at ændre arealanvendelsen, eksempelvis når vi bruger landbrugsjord til byudvikling. LAND-LCA giver et udgangspunkt for at forstå omfanget af klimapåvirkningen ved ændringer i arealanvendelser ud fra informationer om ændringen i arealanvendelse og størrelsen af områderne. 
        </Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          LAND-LCA er tænkt som et supplement til Infra-LCA, som Vejdirektoratet m.fl. har udviklet til at belyse drivhusgasudledninger ved infrastrukturprojekter, herunder materialer og arbejdsprocesser. Begge værktøjer kan danne grundlag for og inspiration til reduktion af drivhusgasemissioner som følge af arealanvendelse.
        </Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          Du kan læse mere om værktøjet her på siden, og så kan du estimere klimapåvirkningen ved din egen plan eller dit eget projekt.
        </Typography>
        {/* <Typography variant="h3" sx={{mb: 1}}>Hvad er direkte ændringer i arealanvendelse?</Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          Direkte ændringer i arealanvendelse (dLUC) beskriver de direkte ændringer, der opstår ved at ændre en
          arealanvendelse til en anden. De direkte ændringer medfører CO2-udledning. Hvis en skov f.eks. omdannes til et
          byområde, så vil størstedelen kulstoffet i skoven omdannes til CO2 emissioner.
        </Typography>

        <Typography variant="h3" sx={{mb: 1}}>Hvad er indirekte ændringer i arealanvendelsen?</Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          Indirekte ændringer i arealanvendelsen (iLuciLUC) beskriver de ændringer, der opstår indirekte ved at ændre en
          arealtype til en anden. De indirekte ændringer medfører også CO2-udledning. Hvis landbrugsjord f.eks. omdannes
          til byområder, skal de afgrødern mad, der før blev dyrket på arealet, produceres et andet sted i verden. Det
          kan
          enten betyde, at der skabes ny landbrugsjord (f.eks. i en regnskov i Brasilien), eller at eksisterende
          landbrug
          intensiveres gennem f.eks. brug af gødning. Dette skaber igen emissioner, som der skal tages højde for.
        </Typography>

        <Typography variant="h3" sx={{mb: 1}}>Hvordan estimerer LAND-LCA CO2-udledningerne?</Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          LAND-LCA estimerer CO2-udledningerne ud fra data på forskning i kulstofindhold i arealanvendelserarealtyper (t
          C/ha i landbrug, skov, græs etc.), produktionskapaciteten af arealer (indikator for potentielle
          afgrødeudbytter), og konsekvenser af ændringer (ændring i efterspørgsel på fx landbrugsjord medfører
          intensivering af eksisterende landbrugsarealer og rydning af skov til fordel for ny landsbrugsarealer).,
          udviklinger i kulstofindhold over tid, mv.
        </Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          Modellen giver mulighed for at vælge mellem permanente ændringer til byformål og infrastruktur og midlertidige
          ændringer på 30 år, hvilket er relevant ved f.eks. solceller. Derudover giver modellen mulighed for at vælge
          mellem en høj og en lav produktivitet af jorden. En høj produktivitet er simplificeret til at svare til
          landbrugsjord, der er udpeget som særlig værdifuld landbrugsjord i kommuneplanen, hvorimod lav produktivitet
          svarer til at arealer er udpeget som indsatsomåder, som sætter begrænsninger på anvendelsen til
          landbrugsformål.
        </Typography>

        <Typography variant="h3" sx={{mb: 1}}>Usikkerheder og antagelser</Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          LAND-LCA er et simpelt værktøj, der bygger på komplekse sammenhænge. Værktøjet er ikke en præcis beregning,
          men
          et indledende estimat, der kan bruges til at afklare omfanget af klimapåvirkninger af arealanvendelse. Der
          skal
          flere beregninger og detaljer om jordbund, mv. til for at give et mere præcist billede af konsekvenserne.
        </Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          I det enkelte tilfælde med en arealanvendelse på fx 10 hektar fra landbrug til by vil det være naturligt at
          sættetille spørgsmålstegn ved, om det kan medføre mere rydning af regnskoven. Det vil formentlig ikke være en
          målbarmærkbar effekt. Men når vi som samfund bruger store arealer til fx byudvikling, infrastrukturer,
          energiproduktion, mv. på bekostning af landbrugsareal, så vil det alt andet lige betyde, at den nedlagte
          landbrugsproduktion skal kompenseres andetsteds – hvilket indebærerpå overordnet plan have en indirekte
          effekter. Og den effekter vil vi overse, hvis vi ikke tager højde for den dem i de enkelte arealanvendelser.
        </Typography>
        <Typography variant="body1" sx={{mb: 2}}>
          Det vil også være nærliggende at sættetille spørgsmålstegn ved, om samfundets udvikling med fokus på at spise
          mindre kød og mere grønt ikke også vil betyde, at vi har brug for færre landbrugsarealer. Det er en god
          observation, men samtidig stiger verdens befolkning hurtigt og klimaforandringerne påvirker mængden af
          landbrugsarealer. Vi har derfor ikke grundlag for at se bort fra den indirekte effekt af vores ændringer i
          arealanvendelse. Derudover over vil ændringer i arealanvendelsen stadig have en effekt – selvom det samlede
          dyrkede areal skulle være faldende i fremtiden.
        </Typography>


        <Typography variant="h6">Hvis du vil læse mere om forskningen bag, anbefaler vi disse
          publikationer:</Typography>
        <Typography variant="body1">
          <ul>
            <li>
              Schmidt J, Weidema B P, Brandão M (2015). A framework for modelling indirect land use changes in life
              cycle
              assessment. Journal of Cleaner Production 99:230-238
            </li>
            <li>
              Webinar: <Link href="https://youtu.be/duJ4e1pYQFw">https://youtu.be/duJ4e1pYQFw</Link>
            </li>
            <li>
              Slides: <Link
              href="https://lca-net.com/wp-content/uploads/iLUC-webinar-15th-Nov_20171115_2.pdf">https://lca-net.com/wp-content/uploads/iLUC-webinar-15th-Nov_20171115_2.pdf</Link>
            </li>
          </ul>
        </Typography> */}

      </Grid>
    </Grid>
  );
}

export default Forside;
