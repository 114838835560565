import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import Papa from "papaparse";
import { createTheme } from "@mui/material/styles";
import { colors } from "../../shared/const";
import { ArrowDropDown, FileUploadOutlined } from "@mui/icons-material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { fetchLUCData } from "../../services/supabaseLuc";
import { fetchLUCDataJson } from "../../services/jsonfileLuc";

import TableHeadPopover from "../../components/ui/TableHeadPopover";
import Breadcrumbs from "../../components/ui/Breadcrumbs";
import { dataStatisticsRoutes } from "../../shared/routes";

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          paper: {
            "&.MuiPaper-root": {
              boxShadow: "none",
            },
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            borderSpacing: "0px 4px",
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: colors.lightGrey,
            height: "100%",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "4px",

            "&::before": {
              borderBottom: "1px solid transparent",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "1px solid transparent",
            },
            "&.Mui-focused:after": {
              borderBottom: "1px solid transparent",
            },
            "&.Mui-focused": {
              backgroundColor: colors.white,
            },

            "&:focus, &$selected, &$focused": {
              backgroundColor: "transparent",
            },
            "&$selected": {
              backgroundColor: "red",
              "&:hover": {
                backgroundColor: "green",
              },
            },
          },
        },
      },
    },
  });

interface LUCDataRow {
  "Land use change from": string;
  "Land use change to": string;
  Land_use_regulation: string;
  Productivity: number;
  Type: string;
  Value: number;
  tidsramme: number;
}

type TCalculationTypes = "m2" | "ha";
type DataRow = (string | number)[];
type RowDeletedType = {
    data: {
        index: number;
        dataIndex: number;
    }[];
};


const data: DataRow[] = [
  // ["55000 m2", 1, 2, 30, 2, "SU", "-", "-", "-"],
  // ["5.5 ha", 1, 2, 100, 6, "SU", "-", "-", "-"],
];

const sumdata = [0, 0, 0, 0];

const Klimaberegning = () => {
  const [tableData, setTableData] = useState(data);
  const [hasUserInput, setHasUserInput] = useState(false);
  const [tableSumData, setTableSumData] = useState(sumdata);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openedPopoverId, setOpenedPopoverId] = useState<string | null>(null);
  const [values, setValues] = useState<any[] | null>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results: { data: any[] }) {
          const valuesArray: React.SetStateAction<any[] | null> = [];
          results.data.map((d) => {
            valuesArray.push(Object.values(d));
          });
          setValues(valuesArray);
        },
      });
    }
  };

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

const handleRowDelete = (rowsDeleted: RowDeletedType) => {
    const deletedIndexes = rowsDeleted.data.map(d => d.index);
    let updatedData = [0, 0, 0, 0];
    let dataToProcess = [];

    if (values && values.length > 0) {
        const updatedValues = values.filter((_, index) => !deletedIndexes.includes(index));
        setValues(updatedValues);
        dataToProcess = updatedValues;  // Use updated values for processing
    } else {
        const updatedTableData = tableData.filter((_, index) => !deletedIndexes.includes(index));
        setTableData(updatedTableData);
        dataToProcess = updatedTableData;  // Use updated table data for processing
    }

    dataToProcess.forEach((row: any[]) => {
        const calculateRowWithHektars =
            (row[0].split(" ")[1] as TCalculationTypes) === "m2"
                ? +row[0].split(" ")[0] / 10000
                : +row[0].split(" ")[0];
        updatedData[0] += calculateRowWithHektars;
        updatedData[1] += row[6] !== "N/A" ? Number(row[6]) : 0;
        updatedData[2] += row[7] !== "N/A" ? Number(row[7]) : 0;
        updatedData[3] += row[8] !== "N/A" ? Number(row[8]) : 0;
    });

    setTableSumData(updatedData);
}








  useEffect(() => {
    if (hasUserInput) {
      const updateData = async () => {
        let updatedData = [...tableData];
        updatedData = await Promise.all(
          updatedData.map(async (row: any[]) => {
            console.log("-------fetchLUCData call JSON FILE --------------")
            console.log(row[1],row[2],row[3],row[5])
            const dbdata: LUCDataRow[] = await fetchLUCDataJson(
              row[1],
              row[2],
              row[3],
              row[5]
            );
            console.log("-------dbdata--------------")
            console.log(dbdata)
            if (!dbdata || dbdata.length < 3) {
              row[6] = "N/A";
              row[7] = "N/A";
              row[8] = "N/A";
              console.log("-------row--------------")
              console.log(row)
              return row;
            }
            const dLucRow = dbdata.find(
              (item: LUCDataRow) => item.Type === "dLUC"
            );
            const dLucValue = dLucRow ? dLucRow.Value : 0;
            const iLucRow = dbdata.find(
              (item: LUCDataRow) => item.Type === "iLUC"
            );
            const iLucValue = iLucRow ? iLucRow.Value : 0;
            const totalLucRow = dbdata.find(
              (item: LUCDataRow) => item.Type === "Total LUC"
            );
            const totalLucValue = totalLucRow ? totalLucRow.Value : 0;
            const calculateRowWithHektars =
            (row[0].split(" ")[1] as TCalculationTypes) === "m2"
              ? +row[0].split(" ")[0] / 10000
              : +row[0].split(" ")[0];
            row[6] = dLucValue * calculateRowWithHektars;
            row[7] = iLucValue * calculateRowWithHektars;
            row[8] = totalLucValue * calculateRowWithHektars;
            return row;
          })
        );
        setTableData(updatedData);
        setHasUserInput(false);
        updateSum(updatedData);
      };
      const updateSum = async (updatedTableData: (number | string)[][]) => {
        let updatedData = [0, 0, 0, 0];
        updatedTableData.forEach((row: any[]) => {
          const calculateRowWithHektars =
            (row[0].split(" ")[1] as TCalculationTypes) === "m2"
              ? +row[0].split(" ")[0] / 10000
              : +row[0].split(" ")[0];
          updatedData[0] += calculateRowWithHektars;
          updatedData[1] += row[6] !== "N/A" ? Number(row[6]) : 0;
          updatedData[2] += row[7] !== "N/A" ? Number(row[7]) : 0;
          updatedData[3] += row[8] !== "N/A" ? Number(row[8]) : 0;
        });
        setTableSumData(updatedData);
      };
      updateData();
    }
  }, [hasUserInput]);

  const addRow = () => {
    const newRow = [
      "0 ha",
      "By/infra",
      "Skov, urørt",
      0,
      0,
      "SU",
      "N/A",
      "N/A",
      "N/A",
    ];
    setTableData((prevTableData) => [...prevTableData, newRow]);
    setHasUserInput(true);
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "Areal",
      options: {
        filter: false,
        customBodyRender: (
          value: string,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ) => {
          const values = value.split(" ");
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                hiddenLabel
                size={"small"}
                variant="filled"
                value={values[0]}
                sx={{ width: "80px", mr: 2 }}
                onChange={(e: any) => {
                  updateValue(e.target.value);
                  let updatedData = [...tableData];
                  updatedData[tableMeta.rowIndex][
                    tableMeta.columnIndex
                  ] = `${e.target.value} ${values[1]}`;
                  setTableData(updatedData);
                  setHasUserInput(true);
                }}
              />
              <TextField
                select={true}
                hiddenLabel={true}
                size={"small"}
                variant={"filled"}
                defaultValue={values[1]}
                onChange={(e: any) => {
                  const val =
                    e.target.value === ("m2" as TCalculationTypes)
                      ? `${+values[0] * 10000} m2`
                      : `${+values[0] / 10000} ha`;
                  updateValue(val);
                  let updatedData = [...tableData];
                  updatedData[tableMeta.rowIndex][tableMeta.columnIndex] = val;
                  setTableData(updatedData);
                  setHasUserInput(true);
                }}
                SelectProps={{
                  IconComponent: (cn) => {
                    return (
                      <ArrowDropDown
                        sx={{
                          color: cn.className.includes("MuiSelect-iconOpen")
                            ? colors.greyDark
                            : "transparent",
                        }}
                      />
                    );
                  },
                }}
                sx={{ width: "90px" }}
              >
                <MenuItem value={"ha"}>ha</MenuItem>
                <MenuItem value={"m2"}>
                  m<sup>2</sup>
                </MenuItem>
              </TextField>
            </Box>
          );
        },
      },
    },
    {
      name: "Fra (type)",
      options: {
        filter: true,
        customBodyRender: (
          value: number,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ) => (
          <FormControl fullWidth>
            <TextField
              select={true}
              hiddenLabel={true}
              size={"small"}
              placeholder={""}
              variant="filled"
              value={value}
              SelectProps={{
                IconComponent: (cn) => {
                  return (
                    <ArrowDropDown
                      sx={{
                        color: cn.className.includes("MuiSelect-iconOpen")
                          ? colors.greyDark
                          : "transparent",
                      }}
                    />
                  );
                },
              }}
              onChange={(e: any) => {
                updateValue(e.target.value);
                let updatedData = [...tableData];
                updatedData[tableMeta.rowIndex][tableMeta.columnIndex] =
                  e.target.value;
                setTableData(updatedData);
                setHasUserInput(true);
              }}
            >
              {dataStatisticsRoutes.map((i) => (
                <MenuItem value={i.value}>{i.label}</MenuItem>
              ))}
            </TextField>
          </FormControl>
        ),
      },
    },
    {
      name: "Til (type)",
      options: {
        filter: true,
        customBodyRender: (
          value: number,
          tableMeta: any,
          updateValue: (arg0: boolean) => void
        ) => {
          return (
            <FormControl fullWidth>
              <TextField
                select={true}
                hiddenLabel={true}
                size={"small"}
                variant={"filled"}
                value={value}
                onChange={(e: any) => {
                  updateValue(e.target.value);
                  let updatedData = [...tableData];
                  updatedData[tableMeta.rowIndex][tableMeta.columnIndex] =
                    e.target.value;
                  setTableData(updatedData);
                  setHasUserInput(true);
                }}
                SelectProps={{
                  IconComponent: (cn) => {
                    return (
                      <ArrowDropDown
                        sx={{
                          color: cn.className.includes("MuiSelect-iconOpen")
                            ? colors.greyDark
                            : "transparent",
                        }}
                      />
                    );
                  },
                }}
              >
                {dataStatisticsRoutes.map((i) => (
                  <MenuItem value={i.value}>{i.label}</MenuItem>
                ))}
              </TextField>
            </FormControl>
          );
        },
      },
    },
    {
      name: "Tidsramme",
      options: {
        filter: false,
        customBodyRender: (
          value: number,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ) => (
          <FormControl fullWidth>
            <TextField
              select={true}
              hiddenLabel={true}
              size={"small"}
              variant={"filled"}
              value={value}
              onChange={(e: any) => {
                updateValue(e.target.value);
                let updatedData = [...tableData];
                updatedData[tableMeta.rowIndex][tableMeta.columnIndex] =
                  e.target.value;
                setTableData(updatedData);
                setHasUserInput(true);
              }}
              SelectProps={{
                IconComponent: (cn) => {
                  return (
                    <ArrowDropDown
                      sx={{
                        color: cn.className.includes("MuiSelect-iconOpen")
                          ? colors.greyDark
                          : "transparent",
                      }}
                    />
                  );
                },
              }}
            >
              <MenuItem value={30}>30 år</MenuItem>
              <MenuItem value={100}>100 år</MenuItem>
            </TextField>
          </FormControl>
        ),
      },
    },
    {
      name: "Kommune",
      options: {
        filter: true,       
        display: false,

        customBodyRender: (

          value: number,
          tableMeta: any,
          // updateValue: (arg0: boolean) => void
        ) => (
          <FormControl fullWidth>
            <TextField
              select={true}
              hiddenLabel={true}
              size={"small"}
              variant={"filled"}
              value={value}
              // onChange={(e: any) => {
              //   updateValue(e.target.value);
              //   let updatedData = [...tableData];
              //   updatedData[tableMeta.rowIndex][tableMeta.columnIndex] =
              //     e.target.value;
              //   setTableData(updatedData);
              // }}
              SelectProps={{
                IconComponent: (cn) => {
                  return (
                    <ArrowDropDown
                      sx={{
                        color: cn.className.includes("MuiSelect-iconOpen")
                          ? colors.greyDark
                          : "transparent",
                      }}
                    />
                  );
                },
              }}
            >
              <MenuItem value={1}>Albertslund</MenuItem>
              <MenuItem value={2}>Allerød</MenuItem>
              <MenuItem value={3}>Assens</MenuItem>
              <MenuItem value={4}>Ballerup</MenuItem>
              <MenuItem value={5}>Billund</MenuItem>
              <MenuItem value={6}>Bornholm</MenuItem>
              <MenuItem value={7}>Brøndby</MenuItem>
              <MenuItem value={8}>Brønderslev</MenuItem>
              <MenuItem value={9}>Dragør</MenuItem>
              <MenuItem value={10}>Egedal</MenuItem>
              <MenuItem value={11}>Esbjerg</MenuItem>
              <MenuItem value={12}>Fanø</MenuItem>
              <MenuItem value={13}>Favrskov</MenuItem>
              <MenuItem value={14}>Faxe</MenuItem>
              <MenuItem value={15}>Fredensborg</MenuItem>
              <MenuItem value={16}>Fredericia</MenuItem>
              <MenuItem value={17}>Frederiksberg</MenuItem>
              <MenuItem value={18}>Frederikshavn</MenuItem>
              <MenuItem value={19}>Frederikssund</MenuItem>
              <MenuItem value={20}>Furesø</MenuItem>
              <MenuItem value={21}>Faaborg-Midtfyn</MenuItem>
              <MenuItem value={22}>Gentofte</MenuItem>
              <MenuItem value={23}>Gladsaxe</MenuItem>
              <MenuItem value={24}>Glostrup</MenuItem>
              <MenuItem value={25}>Greve</MenuItem>
              <MenuItem value={26}>Gribskov</MenuItem>
              <MenuItem value={27}>Guldborgsund</MenuItem>
              <MenuItem value={28}>Haderslev</MenuItem>
              <MenuItem value={29}>Halsnæs</MenuItem>
              <MenuItem value={30}>Hedensted</MenuItem>
            </TextField>
          </FormControl>
        ),
      },
    },
    {
      name: "Jordtype",
      options: {
        filter: true,
        customBodyRender: (
          value: number,
          tableMeta: any,
          updateValue: (arg0: boolean) => void
        ) => {
          return (
            <FormControl fullWidth>
              <TextField
                select={true}
                hiddenLabel={true}
                size={"small"}
                variant={"filled"}
                value={value}
                onChange={(e: any) => {
                  updateValue(e.target.value);
                  let updatedData = [...tableData];
                  updatedData[tableMeta.rowIndex][tableMeta.columnIndex] =
                    e.target.value;
                  setTableData(updatedData);
                  setHasUserInput(true);
                }}
                SelectProps={{
                  IconComponent: (cn) => {
                    return (
                      <ArrowDropDown
                        sx={{
                          color: cn.className.includes("MuiSelect-iconOpen")
                            ? colors.greyDark
                            : "transparent",
                        }}
                      />
                    );
                  },
                }}
              >
                <MenuItem value={"SU"}>Særligt udpeget landbrugsjord</MenuItem>
                <MenuItem value={"IO"}>Indsatsområde</MenuItem>
                <MenuItem value={"OL"}>Øvrig landbrugsområde</MenuItem>
              </TextField>
            </FormControl>
          );
        },
      },
    },
    {
      name: "dLUC",
      options: {
        filter: true,
        customBodyRender: (value: string) => {
          const floatValue = parseFloat(value);
          if (isNaN(floatValue)) {
            return "-";
          } else {
            return floatValue.toFixed(0);
          }
        },
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <th
              key={columnMeta.index}
              onClick={() => updateDirection(columnMeta.index)}
              style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
            >
              <TableHeadPopover
                id={columnMeta.name}
                title={columnMeta.name}
                content={
                  "Direkte ændring i CO2 udledning ifm den ændrede arealanvendelse. Hvis en skov f.eks. omdannes til et byområde, så vil størstedelen af kulstoffet i skoven omdannes til CO2 emissioner."
                }
                open={openedPopoverId === columnMeta.name}
                onOpen={(e) => handlePopoverOpen(e, columnMeta.name)}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
              />
            </th>
          );
        },
      },
    },
    {
      name: "iLUC",
      options: {
        filter: true,
        customBodyRender: (value: string) => {
          const floatValue = parseFloat(value);
          if (isNaN(floatValue)) {
            return "-";
          } else {
            return floatValue.toFixed(0);
          }
        },
        customHeadRender: (columnMeta, updateDirection) => (
          <th
            key={columnMeta.index}
            onClick={() => updateDirection(columnMeta.index)}
            style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
          >
            <TableHeadPopover
              id={columnMeta.name}
              title={columnMeta.name}
              content={
                "Indirekte ændring i CO2 udledning ifm den ændrede arealanvendelse. iLuc kan f.eks. være afgrøder, der skal dyrkes et andet sted hvis vi lokalt omlægger landbrugsjord"
              }
              open={openedPopoverId === columnMeta.name}
              onOpen={(e) => handlePopoverOpen(e, columnMeta.name)}
              onClose={handlePopoverClose}
              anchorEl={anchorEl}
            />
          </th>
        ),
      },
    },
    {
      name: "total",
      options: {
        filter: true,
        customBodyRender: (value: string) => {
          const floatValue = parseFloat(value);
          if (isNaN(floatValue)) {
            return "-";
          } else {
            return floatValue.toFixed(0);
          }
        },
        customHeadRender: (columnMeta, updateDirection) => (
          <th
            key={columnMeta.index}
            onClick={() => updateDirection(columnMeta.index)}
            style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
          >
            <TableHeadPopover
              id={columnMeta.name}
              title={columnMeta.name}
              content={
                "Summen af dLuc og iLuc. Bemærk at værdierne er lineært afhængige af arealstørrelse af omlægningn"
              }
              open={openedPopoverId === columnMeta.name}
              onOpen={(e) => handlePopoverOpen(e, columnMeta.name)}
              onClose={handlePopoverClose}
              anchorEl={anchorEl}
            />
          </th>
        ),
      },
    },
  ];

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="stretch"
      sx={{ pb: 4, px: 6, height: "100%" }}
    >
      <ThemeProvider theme={getMuiTheme()}>
        <Grid
          container
          direction="column"
          justifyContent="space-around"
          alignItems="stretch"
          sx={{ height: "100%" }}
        >
          <Breadcrumbs />
          <MUIDataTable
            data={values && values.length ? values : tableData}
            columns={columns}
            options={{
              onRowsDelete: (e) => {
                handleRowDelete(e);
                console.log(e.data);
              },
              selectableRows: "multiple",
              customToolbar: () => {
                return (
                  <Tooltip title={"Upload CSV"}>
                    <IconButton component="label">
                      <FileUploadOutlined />
                      <input
                        style={{ display: "none" }}
                        type={"file"}
                        id={"csvFileInput"}
                        accept={".csv"}
                        onChange={(e) => handleOnChange(e)}
                        hidden
                      />
                    </IconButton>
                  </Tooltip>
                );
              },
            }}
            title={""}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mb: 2 }}>
            <Typography variant="body1" component="span" sx={{ mb: 1 }}>
                Start din beregning her
            </Typography>
            <Button
              variant={"outlined"}
              sx={{
                alignSelf: "start",
                borderColor: colors.dark,
                color: colors.dark,
                backgroundColor: colors.white,
              }}
              startIcon={<AddCircleOutlineRoundedIcon />}
              onClick={addRow}
            >
              Tilføj
            </Button>
          </Box>
          <Grid
            container
            alignItems="center"
            justifyContent={"space-between"}
            direction="row"
            sx={{ backgroundColor: colors.greyDark, padding: "8px 30px" }}
          >
            <Grid item={true}>
              <Typography variant="h6" color={colors.white}>
                {tableSumData[0]}
                <Typography variant="h6" component={"span"} sx={{ ml: 2 }}>
                  total ha
                </Typography>
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant="h6" color={colors.white}>
                Total ton CO<sub>2</sub>
                <Typography variant="h6" component={"span"} sx={{ mx: 2.5 }}>
                  {Number(tableSumData[1]).toFixed(0)}
                </Typography>
                <Typography variant="h6" component={"span"} sx={{ mx: 2.5 }}>
                  {Number(tableSumData[2]).toFixed(0)}
                </Typography>
                <Typography variant="h6" component={"span"} sx={{ ml: 2.5 }}>
                  {Number(tableSumData[3]).toFixed(0)}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
};
export default Klimaberegning;
