import { useParams } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";

import Breadcrumbs from "../../components/ui/Breadcrumbs";
import { forklaringRoutes } from "../../shared/routes";
import { content1 } from './content1';
import { content2 } from './content2';
import { content3 } from './content3';

import React from "react";

type RouteType = {
  value: number; 
  label: string;
};
type Params = {
  id: string;
};

const contentMapping: Record<string, string | JSX.Element> = {
  "1": content1,
  "2": content2,
  "3": content3,
};


const DataForklaring = () => {
  
const getLabelFromValue = (value: string, routes: RouteType[]): string | null => {
  const numValue = parseInt(value, 10);
  const route = routes.find(route => route.value === numValue);
  return route ? route.label : null;
};

const { id } = useParams<Params>();
  const label = id ? getLabelFromValue(id, forklaringRoutes) : null;
const content = id ? (id in contentMapping ? contentMapping[id] : null) : null;


  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      sx={{
        paddingTop: { xs: 2, md: 4, lg: 6 },
        paddingRight: { xs: 6, md: 10, lg: 20 },
        paddingLeft: { xs: 6, md: 10, lg: 20 },
        paddingBottom: 0,  // No padding at the bottom
        height: "79%"
      }}
    >
      <Box mb={4}>
        {" "}
        <Breadcrumbs />
      </Box>
      
      <Grid sx={{ overflowY: 'auto', maxHeight: 'calc(100% - 20%)' }}> 
        <Typography variant="h1" sx={{mb: 4}}>{label ? `${label}` : "Type ikke fundet"}</Typography>
        
        {content ? content           
            : 
            `Forklaring af ${label} er ikke endnu klar. Den kommer snart.`
          }

        
      </Grid>
    </Grid>
  );
};

export default DataForklaring;
