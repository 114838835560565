import { FC, useState, MouseEvent } from "react";
import {
  AppBar,
  Box,
  Link,
  Container,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../shared/images/logo.png";
import { colors } from "../../../shared/const";
import { dataStatisticsRoutes } from "../../../shared/routes";
import { forklaringRoutes } from "../../../shared/routes";


const navActiveStyle = {
  borderBottom: `solid 3px ${colors.green}`,
  color: colors.white,
  textDecoration: "none",
  paddingBottom: "23px",
  marginRight: "25px",
};

const navStyle = {
  cursor: "pointer",
  borderBottom: `solid 3px transparent`,
  color: colors.lightGrey400,
  textDecoration: "none",
  paddingBottom: "23px",
  marginRight: "25px",
  "&:hover": {
    color: colors.white,
    backgroundColor: colors.white,
  },
};

const Header: FC = () => {
  let navigate = useNavigate();
  const [dataStatisticsAnchorEl, setDataStatisticsAnchorEl] = useState<null | HTMLElement>(null);
  const [forklaringAnchorEl, setForklaringAnchorEl] = useState<null | HTMLElement>(null);

 const handleDataStatisticsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setDataStatisticsAnchorEl(event.currentTarget);
  };

const handleForklaringClick = (event: MouseEvent<HTMLButtonElement>) => {
    setForklaringAnchorEl(event.currentTarget);
  };
  const closeDataStatisticsMenu = () => {
    setDataStatisticsAnchorEl(null);
};

const closeForklaringMenu = () => {
    setForklaringAnchorEl(null);
};



  return (
    <AppBar position="static">
      <Container maxWidth="lg" sx={{ backgroundColor: colors.primary }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="https://dreamsproject.dk/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                color: colors.white,
                textDecoration: "none",
              }}
            >
              Land-LCA
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: colors.grey }}
            />
            <Box sx={{ width: "30%", ml: "16px" }}>
              <Link href="https://dreamsproject.dk/" target="_blank" rel="noopener noreferrer">
                <img src={Logo} alt="Land LCA" style={{ width: "100%" }} />
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ backgroundColor: colors.primary }}>
        <Box>
          <NavLink
            to="/"
            style={({ isActive }) => (isActive ? navActiveStyle : navStyle)}
          >
            Land LCA
          </NavLink>
          <NavLink
            to="/klimaberegning"
            style={({ isActive }) => (isActive ? navActiveStyle : navStyle)}
          >
            Klimaberegning
          </NavLink>

          <span
            // @ts-ignore
            onClick={handleDataStatisticsClick}
            style={navStyle}
          >
            Databeskrivelser
          </span>
          <Menu
            id="basic-menu"
            anchorEl={dataStatisticsAnchorEl}
            open={Boolean(dataStatisticsAnchorEl)}
            onClose={closeDataStatisticsMenu}

            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {dataStatisticsRoutes.map((i) => (
              <MenuItem
                onClick={() => {
                  closeDataStatisticsMenu();
                  navigate(`/databeskrivelse/${i.value}`);
                }}
              >
                {i.label}
              </MenuItem>
            ))}
          </Menu>

          <span
            // @ts-ignore
            onClick={handleForklaringClick} style={navStyle} >
            Forklaringer
          </span>
          <Menu
            id="forklaring-menu"
            anchorEl={forklaringAnchorEl}
            open={Boolean(forklaringAnchorEl)}
            onClose={closeForklaringMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {forklaringRoutes.map((i) => (
              <MenuItem
                onClick={() => {
                  closeForklaringMenu();
                  navigate(`/forklaring/${i.value}`);
                }}
              >
                {i.label}
              </MenuItem>
            ))}
          </Menu>


        </Box>
      </Container>
    </AppBar>
  );
};

export default Header;
