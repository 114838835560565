import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Link, Box } from '@mui/material';


export const content2 = 
    <>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
            LAND-LCA kan både anvendes til at estimere den klimatiske påvirkning ved ændringer i arealanvendelsen i en plan eller et projekt og til at estimere klimatiske påvirkninger ved alternativer. Som et eksempel viser vi her estimeringer knyttet til en konkret dansk kommuneplan fra 2021 med fokus på arealudlæg til boligudbygning.
        </Typography>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
Den konkrete kommuneplan udlægger samlet set 241,5 ha til boliger, og det er primært landbrugsarealer, der udlægges. LAND-LCA estimerer, at arealændringen medfører en klimapåvirkning på næsten 4.000 tons CO<sub>2</sub>-ækvivalenter.
        </Typography>
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
Forskellige boligtyper har forskellige arealforbrug, og en måde at reducere arealforbruget og den tilhørende klimapåvirkning er ved at vælge boliger med et mindre arealforbrug (i det omfang det er muligt). Tabellen viser det oprindelige forslag til fordeling af boligtyper og et alternativ, hvor arealforbruget er mindre, fordi andelen af tæt-lav og etagebyggeri er øget. Det samlede antal boliger udgør 3502 i begge alternativer, og arealerne som omlægges til boliger, fordeler sig med 1,9% skov, 25,3% natur og 72,8% landbrugsjord.
        </Typography>
        <Table size="small" sx={{ "& .MuiTableCell-root": { padding: '4px 8px' } }}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell> 
                        <TableCell>Boligtype</TableCell>
                        <TableCell>Samlet arealforbrug</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            Planens udlæg                            
                        </TableCell>
                        <TableCell>
                            85% åben-lav         
                        </TableCell>
                        <TableCell>
                           241,5 ha
                        </TableCell>
                </TableRow>
                <TableRow>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                            10% tæt-lav 
                        </TableCell>
                        <TableCell>
                           
                        </TableCell>
                </TableRow>
                <TableRow>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                            5% etage-byggeri 
                        </TableCell>
                        <TableCell>
                           
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Planalternativ
                        </TableCell>
                        <TableCell>
                            50% åben-lav
                        </TableCell>
                        <TableCell>
                            129,7 ha
                        </TableCell>
                </TableRow>
                <TableRow>
                        <TableCell>
                            
                        </TableCell>
                        <TableCell>
                            30% tæt-lav
                        </TableCell>
                        <TableCell>
                            
                        </TableCell>
                </TableRow>
                <TableRow>
                        <TableCell>
                            
                        </TableCell>
                        <TableCell>
                            20% etage-byggeri
                        </TableCell>
                        <TableCell>
                            
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Typography variant="caption" display="block" gutterBottom>
                Fordeling af boligtyper i planen og i planalternativet.
        </Typography>
        <br/><br/>
        
        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
            Den første figur viser estimater LAND-LCA på udledning af drivhusgasser fra de to alternativer. Figuren viser, at omfanget af arealanvendelsen har stor betydning for udledningen af drivhusgasser. 
        </Typography>

        <Box>
            <img src="/figur1.png" alt="Figure 1" />
        </Box>

        <Typography variant='body1' component={'p'} sx={{ mb: 2 }}>
            Den næste figur viser klimapåvirkning fordelt på arealtyper som inddrages til boliger ved gennemførelse af planen og planalternativet. Figuren viser, at der er stor forskel på direkte og indirekte klimapåvirkninger alt efter hvilken arealanvendelse, der omdannes til boligformål. Opgørelsen kan være med til at synliggøre, hvad klimapåvirkninger fra ændringer i arealanvendelse skyldes. Det vil være muligt at lave alternativer i forhold til hvilken arealanvendelse, der omdannes til byformål. Der er nemlig stor forskel på udledninger mellem de forskellige arealanvendelser.
        </Typography>

        <Box>
            <img src="/figur2.png" alt="Figure 2" />
        </Box>
    </>